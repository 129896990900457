import React, { useEffect } from "react"
import styled from "styled-components"

import ScrollReveal from "scrollreveal"

import FilmTile from "./film-tile"

const Spacer = styled.div`
  padding-top: 6rem;
  background-color: #eeede9;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 0 1.5rem 6rem 1.5rem;
  background-color: #eeede9;

  @media (min-width: 576px) {
    padding: 0 2.5rem 6rem 2.5rem;
  }
`

export default props => {
  let data = props.data

  useEffect(() => {
    ScrollReveal({
      //   reset: true,
      delay: 1,
      distance: "30px",
    }).reveal(".film-tile")

    return () => {
      ScrollReveal().destroy()
    }
  }, [])
  return (
    <>
      <Spacer />
      <Container>
        {data.map(data => (
          <FilmTile data={data} />
        ))}
      </Container>
    </>
  )
}
