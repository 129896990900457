import React, { useState, useEffect, useContext } from "react"

import SiteContext from "../context/SiteContext"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FilmGrid } from "../components/film"

export default props => {
  let data = props.data.craft.entries.sort(
    (a, b) => b.releaseYear - a.releaseYear
  )
  let context = useContext(SiteContext)

  useEffect(() => {
    context.changeColor("#272727")
  }, [])

  return (
    <Layout>
      <SEO title="Film" />
      <FilmGrid data={data} />
    </Layout>
  )
}

export const query = graphql`
  query {
    craft {
      entries(section: ["film"]) {
        ... on Craft_film_film_Entry {
          uri
          title
          releaseYear
          buttonOneText
          buttonTwoText
          buttonOneLink
          buttonTwoLink
          filmCover {
            ... on Craft_images_Asset {
              url
              imageFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
